import styled, { css } from 'styled-components';
import { device } from '../../theme';

export const DescriptionContainer = styled.div`
  @media ${device.medium} {
    margin-bottom: 32px;
  }
`;

export const BackActionDescription = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.md};
  `}
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
`;
