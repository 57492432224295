import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import BackgroundImageArea from '../../components/BackgroundImageArea';
import CredentialArea from '../../components/CredentialArea/CredentialArea';
import InvoiceItem from '../../components/InvoiceItem';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import Button from '../../components/ui/button';
import Heading from '../../components/ui/Heading';
import { Col, Container, Row } from '../../components/ui/Wrapper';
import invoicesService from '../../services/invoicesService';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import BankSlipEmptyState from './BankSlipEmptyState';
import { BackActionDescription } from './styles';

const BankSlipCopyPage = ({ location, pageContext }) => {
  const [enrollments, setEnrollments] = useState(null);
  const { bankSlipCopyJson } = useStaticQuery(graphql`
    query {
      bankSlipCopyJson {
        description
        title
        backgroundImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  `);

  const { title, description } = bankSlipCopyJson;
  const hasContracts = enrollments && !!enrollments.contracts.length;

  const handleGetEnrollments = data => {
    setEnrollments(data);
  };

  const mapInvoiceItem = invoice => (
    <Col lg={4} md={6} mb="24px" key={invoice.title}>
      <InvoiceItem data={invoice} />
    </Col>
  );

  const mapContractItem = contract => (
    <Row alignitems="center" key={contract.id}>
      <Col lg={12}>
        <Heading as="h4" color="secondary" mt="24px" textalign="center">
          {contract.enroll_code}
        </Heading>
        {contract.parent_name ? (
          <Heading as="h6" color="primary" mb="40px" textalign="center">
            {`Responsável: ${contract.parent_name}`}
          </Heading>
        ) : null}
        <Row>{contract.invoices.map(mapInvoiceItem)}</Row>
      </Col>
    </Row>
  );

  const handleGoBack = () => {
    setEnrollments(null);
  };

  const FormView = () => (
    <CredentialArea
      name="invoice_form"
      title={title}
      description={description}
      onFinishSubmission={handleGetEnrollments}
      handleFetchData={invoicesService.enrollments}
    />
  );

  const DetailsView = () =>
    hasContracts ? (
      <>
        <Row alignitems="center" mb="24px">
          <Col lg={12} textalign="center">
            <Heading as="h2" textalign="center" color="primary">
              {enrollments.name}
            </Heading>

            <Button onClick={handleGoBack} varient="texted" icon={<FaArrowLeft />} iconposition="left">
              <BackActionDescription>Consultar novamente</BackActionDescription>
            </Button>
          </Col>
        </Row>
        {enrollments.contracts.map(mapContractItem)}
      </>
    ) : (
      <BankSlipEmptyState name={enrollments.name} onGoBack={handleGoBack} />
    );

  return (
    <>
      <SEO
        title="Segunda via de boleto - Curso Preparatório Garra - Bacacheri e Portão, Curitiba, PR"
        description="description"
        location={location}
      />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title="2ª Via de boletos" />
      <BackgroundImageArea fluid={{}}>
        <Container>{enrollments ? <DetailsView /> : <FormView />}</Container>
      </BackgroundImageArea>
      <Footer />
    </>
  );
};

BankSlipCopyPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BankSlipCopyPage;
