import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Anchor from '../../components/ui/Anchor';
import Button from '../../components/ui/button';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import ContractImage from '../../data/images/empty-state/contract.png';
import { BackActionDescription, EmptyStateContainer } from './styles';

const BankSlipEmptyState = ({ name, onGoBack }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              whatsapp
            }
          }
        }
      }
    `
  );

  const title = 'Não existem pendências!';
  const { whatsapp } = site.siteMetadata.social;

  return (
    <EmptyStateContainer>
      <img src={ContractImage} alt={title} title={title} />
      <Heading as="h3" mt="28px" textalign="center" color="primary">
        {title}
      </Heading>
      <Text mt="15px" mb="15px" align="center" fontSize="lg">
        Parece que não existem pendências para o aluno <strong>{name}</strong>. Caso algo esteja errado ou tenha alguma
        dúvida,{' '}
        <Anchor path={whatsapp} target="_blank" fontWeight="800" color="secondary" hover={{ color: 'secondaryDark' }}>
          entre em contato conosco
        </Anchor>
        .
      </Text>
      <Button onClick={onGoBack} icon={<FaArrowLeft />} varient="outlined" iconposition="left">
        <BackActionDescription>Consultar novamente</BackActionDescription>
      </Button>
    </EmptyStateContainer>
  );
};

BankSlipEmptyState.propTypes = {
  name: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default BankSlipEmptyState;
