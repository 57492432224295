import React, { useContext } from 'react';
import { FaRegCalendarTimes, FaArrowAltCircleRight, FaWhatsapp, FaFileAlt } from 'react-icons/fa';
import { ThemeContext } from 'styled-components';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'date-fns/parse';
import {
  Container,
  IconInfo,
  ContainerContent,
  ActionDescription,
  Header,
  Title,
  InvoiceItems,
  StatusTag,
} from './styles';
import Heading from '../ui/Heading';
import Text from '../ui/Text';
import Button from '../ui/button';

const InvoiceItem = ({ data }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              whatsapp
            }
          }
        }
      }
    `
  );

  const theme = useContext(ThemeContext);
  const {
    title,
    creation_timestamp: date,
    status_description: statusDescription,
    status,
    formatted_amount: amount,
    action,
  } = data;
  const icon = action.description.toLowerCase() === 'fale conosco' ? <FaWhatsapp /> : <FaFileAlt />;

  const handleClickAction = () => {
    window.open(action.url ?? site.siteMetadata.social.whatsapp, '_blank');
  };

  const mapInvoiceItem = description => (
    <IconInfo>
      <div>
        <FaArrowAltCircleRight color={theme.colors.secondary} />
      </div>
      <Text fontSize="md" ml="12px">
        {description}
      </Text>
    </IconInfo>
  );

  return (
    <Container>
      <ContainerContent>
        <Header>
          <Title>
            <FaRegCalendarTimes size="24px" color={theme.colors.secondary} />
            <Heading fontSize="24px" ml="8px">
              {format(parse(date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}
            </Heading>
          </Title>
          <StatusTag status={status}>{statusDescription}</StatusTag>
        </Header>
        <InvoiceItems>{(title.split(',') ?? []).map(mapInvoiceItem)}</InvoiceItems>
      </ContainerContent>
      <div>
        <Text color="secondary" align="center" fontSize="xxl" fontWeight="bold" mb="14px">
          {amount}
        </Text>
        <Button
          onClick={handleClickAction}
          shape="retangle"
          hover={{ bgColor: 'primaryLight' }}
          icon={icon}
          iconposition="left"
          fullwidth
        >
          <ActionDescription>{action.description}</ActionDescription>
        </Button>
      </div>
    </Container>
  );
};

InvoiceItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    creation_timestamp: PropTypes.string,
    status_description: PropTypes.string,
    status: PropTypes.string,
    formatted_amount: PropTypes.string,
    action: PropTypes.shape({ description: PropTypes.string, url: PropTypes.string }),
  }).isRequired,
};

export default InvoiceItem;
