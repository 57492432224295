import styled, { css } from 'styled-components';
import invoiceStatus from '../../utils/constants/invoiceStatus';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${theme.colors.white};
  `}
`;

export const ContainerContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacings.xl} ${theme.spacings.xl} 0;
    height: 100%;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacings.xl};
    flex-wrap: wrap;
  `}
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const IconInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacings.md};
  `}
`;

export const Action = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
  `}
`;

export const ActionDescription = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacings.md};
  `}
`;

export const InvoiceItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const getTagColor = (status, theme) => {
  switch (status) {
    case invoiceStatus.UNPAID:
      return theme.colors.warning;
    case invoiceStatus.IN_PROTEST:
      return theme.colors.danger;
    case invoiceStatus.PARTIALLY_PAID:
      return theme.colors.info;
    case invoiceStatus.WAITING_PROTEST:
      return theme.colors.dark;
    default:
      return theme.colors.dark;
  }
};

export const StatusTag = styled.span`
  ${({ theme, status }) => css`
    padding: 0 ${theme.spacings.sm};
    border-radius: 4px;
    background-color: ${getTagColor(status, theme)};
    color: ${theme.colors.white};
    font-variant: all-small-caps;
    font-weight: ${theme.fontWeight.bold};
    font-size: 14px;
    white-space: nowrap;
  `}
`;
